<template>
    <section class="bg-white pt-4 pt-md-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <h1 class="bold mb-md-4">Autoprotect your <span class="secondary">account!</span></h1>
                    <div class="zuluGuard my-3 my-md-4">
                        <img src="../../../public/assets/images/zuluguard.png" alt="ZuluGuard" title="Autoprotect your account!">
                    </div>
                    <div class="dualButton d-flex align-items-center justify-content-center mt-4 mt-md-0">
                        <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button fillBtn zulu_btn large me-3 px-md-4">Open Real Account</router-link>
                        <a v-else :href="static_vars.domainURL+'register/'" class="button fillBtn zulu_btn large me-3 px-md-4">Open Real Account</a>
                        <router-link v-if="store.user.access_token" :to="{name:'dashboard_portfolio'}" class="button zulu_btn rounded border-button">Open Demo Account</router-link>
                        <a v-else :href="static_vars.domainURL+'register/'" class="button zulu_btn rounded border-button">Open Demo Account</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="bg-white autoprotect2 pt-4 pt-md-5">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6">
                    <img width="522" class="d-block mx-auto" src="/assets/images/zuluguard-floor-1.png" alt="ZuluGuard™" title="Meet ZuluGuard">
                </div>
                <div class="col-12 col-md-6 text-center text-md-start">
                    <h2 class="mb-2 mt-2 mt-md-0">Meet ZuluGuard</h2>
                    <p>ZuluGuard™ is an account protection feature that monitors each Trader's behavior and automatically removes a Trader when detecting a trading strategy has deviated from its expected loss profile.</p>
                    <p>ZuluGuard™ Capital Protection is available for all Investors and it is mandatory for the European version of our service as it creates a protection shield for your investment capital!</p>
                </div>
            </div>
        </div>
    </section>
    <section class="bg-white autoprotect2 py-4 py-md-5">
        <div class="container">
            <div class="row flex-row-reverse">
                <div class="col-12 col-md-6">
                    <img width="522" class="d-block mx-auto" src="/assets/images/zuluguard-floor-1.png" alt="ZuluGuard™" title="How does it work?">
                </div>
                <div class="col-12 col-md-6 text-center text-md-start">
                    <h2 class="mb-2 mt-2 mt-md-0">How does it work?</h2>
                    <p>The ZuluGuard™ automatically calculates a trading exit value for the trades opened in your account based on your specified capital protection amount. When your threshold is hit, ZuluGuard™ will step in, it will close all open positions and disable the Trader instantly.</p>
                    <p>For detailed information about ZuluGuard, please consult the</p>
                    <router-link to="/zuluguard-guide" class="secondary">ZuluGuard Guide</router-link>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
    }
</script>